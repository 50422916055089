import React from 'react'
import './styles.css'
import premierleagueGoals from '../../premierLeagueGoals.json'

const PremierleagueGoals = () => {
    return (
        <div className='PremierleagueGoalsWrapper'>
            <div className='PremierleagueGoalsText'>
                <h4>Premier League Top Scorer</h4>
            </div>
            <div className='PremierleagueGoalsContent'>
                {
                    premierleagueGoals?.content?.map((content)=>{
                        return(
                            <div className='PremierleagueGoalsData'>
                                <div style={{padding:16}}>
                                    <p style={{marginBottom:"4px"}}>{content?.rank}.</p>
                                    <h6 style={{marginBottom:"4px"}}>{content?.name?.first}</h6>
                                    <h3>{content?.name?.last}</h3>
                                    <div style={{display:"flex" , alignItems:"center" , gap:'4px' , marginTop:"16px"}}>
                                        <img src={`https://resources.premierleague.com/premierleague/badges/50/${content?.currentTeam?.altIds?.opta}@x2.png`} width={30}/>
                                        <h6>{content?.currentTeam?.name}</h6>
                                    </div>
                                    <div style={{display:"flex" , alignItems:"flex-end" , gap:'4px' , marginTop:"8px"}}>
                                        <h1>{content?.value}</h1>
                                        <h6>Goals</h6>
                                    </div>

                                </div>
                                <div style={{display:"flex" , alignItems:"flex-end" , marginRight:"20px"}}>
                                    <img src={`https://resources.premierleague.com/premierleague/photos/players/110x140/${content?.altIds?.opta}.png`} height={190}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PremierleagueGoals