import React from 'react'
import './styles.css'
import mlsPlayerStats from '../../mlsPlayerStats.json'

const MlsLeague = () => {
    return (
        <div className='MlsLeagueWrapper'>
            <div className='MlsLeagueText'>
                <h4>MLS Players Stats</h4>
            </div>
            <div style={{display:"flex" , flexWrap:'wrap' , gap:"24px" , alignItems:"center"  , marginTop:'32px'}}>
                {
                    Object.keys(mlsPlayerStats)?.map((key)=>{
                        return(
                            <div className='MlsLeagueData'>
                                <h4 style={{marginBottom:"24px" , textTransform:"capitalize"}}>{key}</h4>
                                <div className='MlsLeagueDataContent'>
                                    {
                                        mlsPlayerStats[key]?.slice(0,1)?.map((player)=>{
                                            return(
                                                <div className='MlsLeagueDataContentFirst'>
                                                    <div style={{padding:16}}>
                                                        <h3>{player?.player?.first_name.charAt(0)}. {player?.player?.last_name}</h3>
                                                            <p style={{marginBottom:"4px"}}>{player?.club?.name}</p>
                                                        <h1>
                                                            {key === "goals" && player?.regular_season_statistics?.goals}
                                                            {key === "assists" && player?.regular_season_statistics?.goal_assist}
                                                            {key === "key Passes" && player?.regular_season_statistics?.total_att_assist}
                                                            {key === "shots" && player?.regular_season_statistics?.total_scoring_att}
                                                        </h1>
                                                    </div>
                                                    <div style={{display:"flex" , alignItems:"flex-end" , justifyContent:"flex-end", height:"100%" , marginRight:"24px"}}>
                                                        <img src={`https://images.mlssoccer.com/image/private/w_175,h_175,c_pad/f_png/mls/${player?.image}`} width={130}/> 
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        mlsPlayerStats[key]?.slice(1,4)?.map((player)=>{
                                            return(
                                                <div style={{border:"1px solid black" , marginTop:'8px' , padding:"8px" , borderRadius:'16px' , display:"flex" , justifyContent:"space-between"}}>
                                                    <div style={{display:"flex" , gap:'8px'}}>
                                                        <h5 style={{margin:0}}>{player?.player?.first_name.charAt(0)}. {player?.player?.last_name}</h5>
                                                        <p style={{margin:0}}>{player?.club?.abbreviation}</p>
                                                    </div>
                                                    <h5 style={{margin:0}}>
                                                            {key === "goals" && player?.regular_season_statistics?.goals}
                                                            {key === "assists" && player?.regular_season_statistics?.goal_assist}
                                                            {key === "key Passes" && player?.regular_season_statistics?.total_att_assist}
                                                            {key === "shots" && player?.regular_season_statistics?.total_scoring_att}
                                                        </h5>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MlsLeague