import React, { useRef, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import moment from 'moment';
import { Autoplay } from 'swiper/modules';


export default function News({ news, loading }) {

    return (
        <>
            <Container className='NewsWrapper'>
                {
                    loading ?
                        <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner animation="grow" style={{ backgroundColor: "#000" }} />
                        </div>
                        :
                        <Swiper 
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                        className="mySwiper"
                         spaceBetween={20} 
                         modules={[Autoplay]}
                         >
                            {
                                news.map((item)=>{
                                    return(
                                        <SwiperSlide style={{ backgroundImage: `url(${item?.enclosures[0].url})` }}>
                                          <div className='mySwiperOverlay'>
                                            <div style={{display:"flex" , alignItems:"center", gap:'16px'}}>
                                                <div className='mySwiperCategory'>
                                                    {item?.category}
                                                </div>
                                                <div className='mySwiperPublished'>
                                                    <p>{moment(item?.published).format('YYYY-MM-DD')}</p>
                                                </div>
                                            </div>
                                            <div className='mySwiperTitle'>
                                                <h3>
                                                    {item?.title}
                                                </h3>
                                            </div>
                                            <div className='mySwiperDesc'>
                                                <h5>
                                                    {item?.description}
                                                </h5>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                }
            </Container>
        </>
    )
}
