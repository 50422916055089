import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.css'
import Rating from './rating'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination, Keyboard, Autoplay } from "swiper/modules";

import avatar1 from '../../assets/images/avatar1.jpg'
import avatar2 from '../../assets/images/avatar2.jpg'
import avatar3 from '../../assets/images/avatar3.jpg'
import avatar4 from '../../assets/images/avatar4.jpg'
import avatar5 from '../../assets/images/avatar5.jpg'
import avatar6 from '../../assets/images/avatar6.jpg'
import avatar7 from '../../assets/images/avatar7.jpg'
import avatar8 from '../../assets/images/avatar8.jpg'
import avatar9 from '../../assets/images/avatar9.jpg'
import avatar10 from '../../assets/images/avatar10.jpg'
import avatar11 from '../../assets/images/avatar11.jpg'
import avatar12 from '../../assets/images/avatar12.jpg'



const Reviews = ({ title, subTitle }) => {
    const reviews = [
        {
            id: 25,
            name: "Thomas Anderson",
            description: "BetMaster is the best in the business when it comes to online sports betting. They provided exceptional service and exceeded my expectations.",
            rating: '5',
            avatar: avatar2,
        },
        {
            id: 26,
            name: "Emma Walker",
            description: "I couldn't be happier with the outcome of using BetMaster. They offered great odds and a seamless betting experience.",
            rating: '5',
            avatar: avatar1,
        },
        {
            id: 27,
            name: "Christopher Harris",
            description: "BetMaster's team of experts went above and beyond to help me with my bets. Their knowledge and skills are outstanding.",
            rating: '5',
            avatar: avatar4,
        },
        {
            id: 28,
            name: "Aria Thompson",
            description: "I highly recommend BetMaster for their exceptional service and commitment to their customers. They truly understand the importance of a reliable betting platform.",
            rating: '5',
            avatar: avatar3,
        },
        {
            id: 29,
            name: "Maxwell White",
            description: "BetMaster is the go-to solution for anyone looking to place bets on sports. Their expertise and dedication are unparalleled.",
            rating: '5',
            avatar: avatar6,
        },
        {
            id: 30,
            name: "Madison Turner",
            description: "I am grateful to BetMaster for providing a fantastic betting experience. Their professionalism and support made a significant difference.",
            rating: '5',
            avatar: avatar5,
        },
        {
            id: 31,
            name: "William Green",
            description: "BetMaster is a trustworthy partner for all your sports betting needs. Their team has the necessary skills and knowledge to tackle any challenge.",
            rating: '5',
            avatar: avatar8,
        },
        {
            id: 32,
            name: "Chloe Adams",
            description: "I had almost given up hope on finding a reliable betting platform, but BetMaster proved me wrong. They are truly experts in their field.",
            rating: '5',
            avatar: avatar7,
        },
        {
            id: 33,
            name: "Alexander Moore",
            description: "BetMaster's dedication and professionalism are commendable. They offered great odds and provided excellent support throughout the process.",
            rating: '5',
            avatar: avatar10,
        },
        {
            id: 34,
            name: "Scarlett Phillips",
            description: "I cannot thank BetMaster enough for their outstanding service. They truly care about their customers and work tirelessly to provide the best betting experience.",
            rating: '5',
            avatar: avatar9,
        },
        {
            id: 35,
            name: "Henry Carter",
            description: "BetMaster is the top choice for anyone in need of a reliable sports betting platform. Their expertise and dedication set them apart from the rest.",
            rating: '5',
            avatar: avatar12,
        },
        {
            id: 36,
            name: "Victoria Lee",
            description: "I had a great experience with BetMaster. They offered great odds and provided excellent customer support throughout the process.",
            rating: '5',
            avatar: avatar11,
        }
    ];


    return (
        <div className='testimonialWrapper'>
            <Container>
                <div className='testimonialTitleContainer'>
                    <h3>
                        Reviews
                    </h3>
                </div>

                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    loop={true}
                    keyboard={{
                        enabled: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    modules={[EffectCoverflow, Pagination, Keyboard, Autoplay]}
                    className="reviewSwiper"
                >
                    {
                        reviews.map(testimonial => (
                            <>
                                <SwiperSlide>
                                    <div className='testimonialContainer' key={testimonial.id}>
                                        <div style={{ display: 'flex' }}>
                                            <div className='testimonialAvatar'>
                                                <img src={testimonial.avatar} width={70} height={70} alt='testimonialAvatar' />
                                            </div>
                                            <div className='ratingNameContainer'>
                                                <div className="nameContainer">
                                                    <p>{testimonial.name}</p>
                                                </div>
                                                <div className='ratingContainer'>
                                                    <Rating rating={testimonial.rating} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='testimonialBodyContainer'>
                                            <p>{testimonial.description}</p>
                                        </div>
                                    </div>
                                </SwiperSlide >
                            </>
                        ))
                    }
                </Swiper>
            </Container>
        </div >
    )
}

export default Reviews