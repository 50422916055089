import React from 'react'
import './styles.css'
import nflTopClubs from '../../nflTopClubs.json'

const NhlTopClubs = () => {
    return (
        <div className='nhlTopClubsWrapper'>
            <div className='nhlTopClubsText'>
                <h4>NFL Top Clubs</h4>
            </div>
            <div className='nhlTopClubsContent'>
                {
                    nflTopClubs.clubs?.map((club)=>{
                        return(
                           <div className='nhlTopClubData'>
                                <img src={club?.logo} width={100} height={100}/>
                                <h5>{club.name}</h5>
                                <div style={{display:"flex" , justifyContent:"space-between", width:"100%" , padding:"0px 8px"}}>
                                    <p style={{marginBottom:"4px"}}>FMG: {club?.fmg}</p>
                                    <p style={{margin:"0"}}>FG %: {club?.fg}</p>
                                </div>
                                <div style={{display:"flex" , justifyContent:"space-between", width:"100%" , padding:"0px 8px"}}>
                                    <p>XPM: {club?.xpm}</p>
                                    <p>XP Pct: {club?.xp_pct}</p>
                                </div>
                           </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NhlTopClubs