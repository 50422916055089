import React, { useState } from 'react'
import './styles.css'
import nhlLeaders from '../../nhlLeaders.json'

const NhlLeaders = () => {

    return (
        <div className='NhlLeadersWrapper'>
            <div className='NhlLeadersText'>
                <h4>NHL Stats</h4>
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
                {
                    Object.keys(nhlLeaders).map((key) => (
                        <div style={{ width: "300px" }}>
                            <h4 style={{ textTransform: "capitalize" }}>{key}</h4>
                            <div style={{ display: "flex", flexDirection: 'column', gap: "16px" }}>
                                {
                                    nhlLeaders[key]?.map((value) => {
                                        console.log(value)
                                        return (
                                            <div style={{ border: "1px solid black", borderRadius: "100px", display: "flex" , alignItems:"center" , justifyContent:"space-between"}}>
                                                <div style={{display: "flex" , alignItems:"center"}}>
                                                    <img
                                                        width={60}
                                                        height={60}
                                                        style={{ borderRadius: "100px" }}
                                                        src={value?.headshot}
                                                        alt={`${value?.firstName?.default} ${value?.lastName?.default}`}
                                                    />
                                                    <div>
                                                        <p style={{margin:0}}><b>{value.firstName?.default} {value.lastName?.default}</b></p>
                                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                                            <img src={value?.teamLogo} width={30} height={30} alt={`${value?.firstName?.default} ${value?.lastName?.default}card`} />
                                                            <p style={{margin:0}}>{value.teamAbbrev}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div style={{ padding:16 , borderRadius:"100px"}}>
                                                    <h4 style={{margin:0}}>{value?.value}</h4>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default NhlLeaders