import React, { useState } from 'react'
import './styles.css'
import { leagues } from '../usaLeagues/data'
import games from './games.json'
import moment from 'moment'
import { Carousel, Container } from 'react-bootstrap'

const Games = () => {
    const [gameLeagueSelected, setGameLeagueSelected] = useState('NHL')

    return (
        <div className='GamesWrapper'>
            <div className='GamesText'>
                <h4>Matches</h4>
            </div>
            <div style={{display:"flex" , gap:'24px'}}>
                <div className='GamesHeaderContainer'>
                    {
                        leagues?.map((league) => {
                            return (
                                <div className={league?.leagueName === gameLeagueSelected ? 'GamesHeaderCardSelected' : 'GamesHeaderCard'} onClick={() => setGameLeagueSelected(league?.leagueName)}>
                                    <img src={league?.icon} width={30} height={30} />
                                    <p>{league?.leagueName}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='GamesContentContainer'>
                    {
                        // games[gameLeagueSelected]?.games ?
                        //     <>
                        //         {
                        //             games[gameLeagueSelected]?.games?.map((game, index) => {
                        //                 return (
                        //                     <>
                        //                         <div className='GamesContent'>
                        //                             <h4>{moment().add(game?.date, "days").format("dddd DD/MM")}</h4>
                        //                         </div>
                        //                         <div className='GamesContentCardsContainer'>
                        //                             {
                        //                                 game?.games?.map((match, index) => {
                        //                                     return (
                        //                                         <div className='GamesContentCard'>
                        //                                             <div className='GamesContentCardTeamsContainer'>
                        //                                                 <div className='GamesContentCardHomeContainer'>
                        //                                                     <div style={{display:"flex", alignItems:"center" , gap:'4px'}}>
                        //                                                         <img src={match?.homeTeam?.logo} width={50} height={50} />
                        //                                                         <p>{match?.homeTeam?.abbrev}</p>
                        //                                                     </div>
                        //                                                     {
                        //                                                         match?.homeTeam?.score &&
                        //                                                         <p className='GamesContentCardScore'>{match?.homeTeam?.score}</p>
                        //                                                     }
                        //                                                 </div>

                        //                                                 <div className='GamesContentCardAwayContainer'>
                        //                                                     <div style={{display:"flex", alignItems:"center", gap:'4px'}}>
                        //                                                         <img src={match?.awayTeam?.logo} width={50} height={50} />
                        //                                                         <p>{match?.awayTeam?.abbrev}</p>
                        //                                                     </div>
                        //                                                     {
                        //                                                         match?.awayTeam?.score &&
                        //                                                         <p className='GamesContentCardScore'>{match?.awayTeam?.score}</p>
                        //                                                     }
                        //                                                 </div>
                        //                                             </div>
                        //                                             <div className='GamesContentCardStartTime'>
                        //                                                 <p>
                        //                                                     {
                        //                                                         (!match?.homeTeam?.score && !match?.awayTeam?.score) &&
                        //                                                         <p>{moment(match?.startTimeUTC).format('HH:mm a')}</p>
                        //                                                     }
                        //                                                 </p>
                        //                                             </div>
                        //                                             <div className='GamesContentCardTicketLink'>
                        //                                                 <a href={match?.ticketsLink ? match?.ticketsLink : match?.highlights} target='_blank'>
                        //                                                     <p>{match?.ticketsLink ? "Tickets" :
                        //                                                         match?.highlights ? "Highlights" : ""}</p>
                        //                                                 </a>
                        //                                             </div>
                        //                                         </div>
                        //                                     )
                        //                                 })
                        //                             }
                        //                         </div>
                        //                     </>

                        //                 )
                        //             })
                        //         }
                        //     </>
                        //     :
                        //     <div className='noGamesContainer'>
                        //         <img src={nogames}  alt='nogames' width='60%' height='100%'/>
                        //     </div>
                    }
                    <Container>
                        <Carousel style={{ height: '50vh' }} indicators={false}>
                            {
                                games[gameLeagueSelected]?.games?.map((match, index) => (
                                    <Carousel.Item key={index} className='gamesCarouselItems'>
                                        <div className='gamesContainerOverlay'>
                                            <div className='gamesContainerImage'>
                                                <img src={match?.homeTeam?.logo} alt="Home Team Logo" style={{ width: '500px', height: 'auto' }} />
                                                <img src={match?.awayTeam?.logo} alt="Away Team Logo" style={{ width: '500px', height: 'auto' }} />
                                            </div>
                                            <div className='gamesOverlay'>
                                                <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                                                    <img src={match?.homeTeam?.logo} alt="Home Team Logo" style={{ width: '300px', height: 'auto' }} />
                                                    <h2>{match?.homeTeam?.name?.default}</h2>
                                                </div>
                                                <div style={{display:'flex', alignItems:"center" , flexDirection:'column' , gap:"24px"}}>
                                                    <h5>
                                                        {moment().add(match?.gameDate, "days").format("dddd DD/MM")}
                                                    </h5>
                                                    {
                                                        (match?.homeTeam?.score && match?.awayTeam?.score) ?
                                                            <h1>{match?.homeTeam?.score} : {match?.awayTeam?.score}</h1>
                                                            :
                                                            <h1>{moment(match?.startTimeUTC).format('HH:mm a')}</h1>
                                                    }
                                                    <div className='GamesContentCardTicketLink'>
                                                        <a href={match?.ticketsLink ? match?.ticketsLink : match?.highlights} target='_blank'>
                                                            <h5 style={{margin:"0"}}>{match?.ticketsLink ? "Tickets" :
                                                                match?.highlights ? "Highlights" : ""}</h5>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                                                    <img src={match?.awayTeam?.logo} alt="Away Team Logo" style={{ width: '300px', height: 'auto' }} />
                                                    <h2>{match?.awayTeam?.name?.default}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>

                    </Container>
                </div>
            </div>

        </div>
    )
}

export default Games